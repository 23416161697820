import { resolveComponent as _resolveComponent, createVNode as _createVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-02b0a199"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "InputFileComponent" }
const _hoisted_2 = { class: "ignore" }
const _hoisted_3 = ["id", "accept"]
const _hoisted_4 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputComponent = _resolveComponent("InputComponent")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_InputComponent, {
      style: {"display":"none"},
      modelValue: _ctx.dataFile,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dataFile) = $event)),
      name: _ctx.name,
      required: ""
    }, null, 8, ["modelValue", "name"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("input", _mergeProps({
        class: "hidden",
        id: `input-${_ctx.name}`,
        type: "file",
        onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onFileSelected && _ctx.onFileSelected(...args))),
        accept: _ctx.typeInput
      }, _ctx.$attrs), null, 16, _hoisted_3),
      _createElementVNode("label", {
        for: `input-${_ctx.name}`,
        class: "cursor-pointer bg-white px-2 w-full flex border-black border py-4 px-2"
      }, _toDisplayString(_ctx.fileName == "" ? "Selecione um arquivo" : _ctx.fileName), 9, _hoisted_4)
    ])
  ]))
}