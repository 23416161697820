import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "Container-Component" }
const _hoisted_2 = { class: "w-full px-10 md:px-40 lg:px-40 mb-8 mt-4" }
const _hoisted_3 = { class: "bg-white border" }
const _hoisted_4 = {
  key: 0,
  class: "flex items-center justify-between px-4 py-2 border-b border-gray-200"
}
const _hoisted_5 = {
  key: 0,
  class: "lg:text-2xl md:text-2xl text-xl font-bold"
}
const _hoisted_6 = { class: "p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (!('no-header' in _ctx.$attrs))
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              (_ctx.header)
                ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.header), 1))
                : _createCommentVNode("", true),
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "headerLeft")
              ]),
              _createElementVNode("div", null, [
                _renderSlot(_ctx.$slots, "headerRight")
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "body")
        ])
      ])
    ])
  ]))
}