
import { defineComponent } from "vue";
export default defineComponent({
    props: {
        label: {
            type: String,
            required: true
        },
        type: {
            type: String as () => "button" | "submit" | "reset" | undefined,
            default: () => 'button'
        },
        id: {
            type: String,
            required: false
        },
        classButton: {
            type: String,
            default: () => 'bg-[#221e1f] px-10 py-4 my-2 text-white'
        }
    },
    methods: {},
});
