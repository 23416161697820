import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "CarouselCompoent w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_carousel = _resolveComponent("carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_carousel, {
      class: "w-full",
      "items-to-show": 1,
      wrapAround: true,
      transition: 500
    }, {
      addons: _withCtx(() => [
        _createVNode(_component_Navigation)
      ]),
      default: _withCtx(() => [
        _renderSlot(_ctx.$slots, "slides")
      ]),
      _: 3
    })
  ]))
}