
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: String,
            required: false as boolean,
        },
        value: {
            type: [String, Number],
            required: false as boolean,
        },
        modelValue: {
            type: [String, Number],
            required: false as boolean,
        },
        id: {
            type: String,
            required: false as boolean,
        },
        name: {
            type: String,
            required: true as boolean,
        },
        placeholder: {
            type: String,
            required: false as boolean,
        },
        width: {
            type: String,
            required: false as boolean,
            default: () => "auto"
        },
        type: {
            type: String,
            required: false as boolean,
            default: () => "text"
        },
        radius: {
            type: String,
            required: false as boolean,
            default: () => "none"
        },
        borderColor: {
            type: String,
            required: false as boolean,
            default: () => "black"
        },
    },
    methods: {
        AjustaVModel(event: Event) {
            const target = event.target as HTMLInputElement;
            this.$emit("update:modelValue", target.value);
        },
    },
});
