
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        header: {
            type: String,
            required: true
        },
    },
});
