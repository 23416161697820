
import { defineComponent } from 'vue';
import SvgIcon from '@jamescoyle/vue-icon';
import { mdiMenu } from '@mdi/js';

export default defineComponent({
    components: {
		SvgIcon
	},
    data() {
        return {
            showSolucoes: false as Boolean,
            showSolucoesVinilicos: false as Boolean,
            showContato: false as Boolean,
            showOndeEncontrar: false as Boolean,
            path: mdiMenu as string,
            showAsideMenu: false as Boolean,
            isMobile: window.innerWidth <= 1250 as Boolean
        }
    },
    watch: {
        $route(to) {
            const rota = to.name?.toString();
            document.title = `Neoline | ${rota}`;
        },
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth <= 1250
        },
        handleClickOutside(event: Event, route = "") {
            switch (route) {
                case 'solucoes':
                    this.showSolucoesVinilicos = false;
                    this.showContato = false;
                    this.showOndeEncontrar = false;
                    this.showSolucoes = !this.showSolucoes
                    break;
                case 'solucoesvinilicos':
                    this.showSolucoes = !this.showSolucoes;
                    this.showContato = false;
                    this.showOndeEncontrar = false;
                    this.showSolucoesVinilicos = !this.showSolucoesVinilicos
                    break;
                case 'contato':
                    this.showSolucoes = false;
                    this.showSolucoesVinilicos = false;
                    this.showOndeEncontrar = false;
                    this.showContato = !this.showContato
                    break;
                case 'ondeencontrar':
                    this.showSolucoes = false;
                    this.showSolucoesVinilicos = false;
                    this.showContato = false;
                    this.showOndeEncontrar = !this.showOndeEncontrar
                    break;
                default:
                    this.showSolucoes = false;
                    this.showSolucoesVinilicos = false;
                    this.showContato = false;
                    this.showOndeEncontrar = false;
                    break;
            }
        }
    },
    mounted() {
        document.addEventListener("click", this.handleClickOutside);
        window.addEventListener('resize', this.handleResize);
    },
    beforeDestroy() {
        document.removeEventListener("click", this.handleClickOutside);
    },
});
