import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: () => import("../views/HomeView.vue")
    },
    {
      path: '/sobre-nos',
      name: 'Sobre Nós',
      component: () => import("../views/SobreNosView.vue")
    },
    {
      path: '/solucoes',
      name: 'Soluções',
      component: () => import("../views/solucoes/SolucoesView.vue")
    },
    {
      path: '/solucoes/vinilicoslvt/lvtconcept',
      name: 'LVT CONCEPT',
      component: () => import("../views/solucoes/vinilicoslvt/LvtConceptView.vue")
    },
    {
      path: '/solucoes/vinilicoslvt/lvtmajestic',
      name: 'LVT MAJESTIC',
      component: () => import("../views/solucoes/vinilicoslvt/LvtMajesticView.vue")
    },
    {
      path: '/solucoes/vinilicoslvt/lvtprime',
      name: 'LVT PRIME',
      component: () => import("../views/solucoes/vinilicoslvt/LvtPrimeView.vue")
    },
    {
      path: '/blog',
      name: 'Blog',
      component: () => import("../views/BlogView.vue")
    },
    {
      path: '/blog/:articleName',
      name: ':articleName',
      component: () => import('../views/ArtigoBlogView.vue')
    },
    {
      path: '/contato/faleconosco',
      name: 'Fale Conosco',
      component: () => import("../views/contato/FaleConoscoView.vue")
    },
    {
      path: '/contato/duvidas',
      name: 'Duvidas',
      component: () => import("../views/contato/DuvidasView.vue")
    },
    {
      path: '/contato/trabalheconosco',
      name: 'Trabalhe Conosco',
      component: () => import("../views/contato/TrabalheConoscoView.vue")
    },
    {
      path: '/ondeencontrar/lojas',
      name: 'Lojas',
      component: () => import("../views/ondeencontrar/LojasView.vue")
    },
    {
      path: '/ondeencontrar/representantes',
      name: 'Representantes',
      component: () => import("../views/ondeencontrar/RepresentantesView.vue")
    },
    {
      path: '/ondeencontrar/sejaumrevendedor',
      name: 'Seja um Revendedor',
      component: () => import("../views/ondeencontrar/SejaUmRevendedorView.vue")
    },
    {
      path: '/downloads',
      name: 'Downloads',
      component: () => import("../views/DownloadsView.vue")
    },
    {
      path: '/admin',
      name: 'Administrar',
      component: () => import("../views/AdministrarView.vue")
    },
    {
      path: '/admin/Duvidas',
      name: 'Cadastro Duvidas',
      component: () => import("../views/cadastros/DuvidasCadastroView.vue")
    },
    {
      path: '/admin/Blog',
      name: 'Cadastro Blog',
      component: () => import("../views/cadastros/BlogsCadastroView.vue")
    },
    {
      path: '/admin/Usuario',
      name: 'Cadastro Usuário',
      component: () => import("../views/cadastros/UsuarioCadastroView.vue")
    },
    // INICIO COMENTARIO UPLOAD ARQUIVOS
    // {
    //   path: '/admin/Downloads',
    //   name: 'Cadastro Downloads',
    //   component: () => import("../views/cadastros/DownloadsCadastroView.vue")
    // },
    // FIM COMENTARIO UPLOAD ARQUIVOS
    {
      path: '/admin/Lojas',
      name: 'Cadastro Lojas',
      component: () => import("../views/cadastros/LojaCadastroView.vue")
    },
    {
      path: '/admin/Representantes',
      name: 'Cadastro Representate',
      component: () => import("../views/cadastros/RepresentateCadastroView.vue")
    },
    {
      path: '/admin/Depoimentos',
      name: 'Cadastro Depoimentos',
      component: () => import("../views/cadastros/DepoimentosCadastroView.vue")
    },
  ]
})

export default router
