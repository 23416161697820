
import { defineComponent } from 'vue';
import { GetDataUser } from './store/UserStore';
import axios, { AxiosResponse, AxiosError } from 'axios'

export default defineComponent({
  setup() {
    const dataUser = GetDataUser();
    return { dataUser };
  },
  data() {
    return {
      is404: false as Boolean,
      isConstruction: false as Boolean
    }
  },
  watch: {
    $route(currentRoute) {
      this.is404 = currentRoute.matched.length === 0;
      this.isConstruction = process.env.VUE_APP_CONSTRUCTION_ENABLED == 1;
    }
  },
  async mounted () {
    axios.interceptors.response.use(
      (response: AxiosResponse) => {
          return response;
      },
      (error: AxiosError) => {
        if (error.response?.data === 401) {
          if (['/admin'].includes(this.$route.fullPath)) {
            this.$router.push("/admin");
          }    
        }
        return error;
      }
    );

    await this.dataUser.VerifyUser();
  },
})

