import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, mergeProps as _mergeProps, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["name", "id", "modelValue", "value"]
const _hoisted_3 = { value: "" }
const _hoisted_4 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`Selectlist-Component ${_ctx.divClass}`)
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "mr-8",
          for: _ctx.id || _ctx.name
        }, _toDisplayString(_ctx.label), 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("select", _mergeProps({
      name: _ctx.name,
      id: _ctx.id || _ctx.name,
      modelValue: _ctx.modelValue,
      value: _ctx.value || _ctx.modelValue,
      class: `${_ctx.selectClass}`,
      onFocusin: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('focusin', $event))),
      onFocusout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('focusout', $event))),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focus', $event))),
      onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('change', $event))),
      onKeydown: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('keydown', $event))),
      onKeyup: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('keyup', $event))),
      onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('click', $event))),
      onInput: _cache[7] || (_cache[7] = ($event: any) => (_ctx.AjustaVModel()))
    }, _ctx.$attrs), [
      _createElementVNode("option", _hoisted_3, _toDisplayString(_ctx.placeholder), 1),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (data, index) => {
        return (_openBlock(), _createElementBlock("option", _mergeProps({
          value: data.id,
          key: index
        }, _ctx.value == data.id ? 'selected' : ''), _toDisplayString(data.name), 17, _hoisted_4))
      }), 128))
    ], 16, _hoisted_2)
  ], 2))
}