import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "CardComponent" }
const _hoisted_2 = { class: "px-4 md:w-[60rem] my-8 lg:w-[60rem] w-full" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        style: _normalizeStyle(`background: ${_ctx.background}`),
        class: "rounded-2xl shadow-xl lg:p-8 md:p-6 p-4 h-max"
      }, [
        (_ctx.title)
          ? (_openBlock(), _createElementBlock("h2", {
              key: 0,
              style: _normalizeStyle(`color: ${_ctx.textColor};`),
              class: "lg:text-2xl md:text-2xl lg:mb-4 md:mb-4 text-xl font-bold mb-2"
            }, _toDisplayString(_ctx.title), 5))
          : _createCommentVNode("", true),
        _createElementVNode("span", {
          style: _normalizeStyle(`color: ${_ctx.textColor};`)
        }, _toDisplayString(_ctx.text), 5)
      ], 4)
    ])
  ]))
}