
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        background: {
            type: String,
            default: () => "#f8f8f8"
        },
        textColor: {
            type: String,
            default: () => "#111"
        }, 
        title: {
            type: String,
            required: false
        },
        text: {
            type: String,
            required: true
        },
    },
});
