import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import VueAxios from 'vue-axios';
import Maska from "maska";
import VueSweetalert2 from "vue-sweetalert2";
import { createPinia } from 'pinia';
import { QuillEditor } from '@vueup/vue-quill'

/* Styles */
import './assets/tailwind.css';
import './assets/main.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';

/* Components */
import NavBarComponent from "./components/NavBarComponent.vue";
import FooterComponent from "./components/FooterComponent.vue";
import AsideMenu from "./components/AsideMenu.vue";
import ConstrucaoComponent from "./components/ConstrucaoComponent.vue";
import InputComponent from "./components/InputComponent.vue";
import TextAreaComponent from "./components/TextAreaComponent.vue";
import ButtonComponent from "./components/ButtonComponent.vue";
import CardComponent from "./components/CardComponent.vue";
import CarouselCompoent from "./components/CarouselCompoent.vue";
import CRUDComponent from "./components/CRUDComponent.vue";
import ContainerComponent from "./components/ContainerComponent.vue";
import InputFileComponent from "./components/InputFileComponent.vue";
import SelectlistComponent from "./components/SelectlistComponent.vue";
import RoundedLinkComponent from "./components/RoundedLinkComponent.vue"
import DisplayRevestimentoComponent from "./components/DisplayRevestimentoComponent.vue"

axios.defaults.baseURL = "https://neoapi.neolinerevestimentos.com.br/api/"; //Produção
// axios.defaults.baseURL = "http://localhost:8000/api/"; //local
axios.defaults.headers.common["Accept"] = "application/json";

const pinia = createPinia();
const app = createApp(App);

/* component */
app.component("NavBarComponent", NavBarComponent);
app.component("FooterComponent", FooterComponent);
app.component("AsideMenu", AsideMenu);
app.component("InputComponent", InputComponent);
app.component("ConstrucaoComponent", ConstrucaoComponent);
app.component("TextAreaComponent", TextAreaComponent);
app.component("ButtonComponent", ButtonComponent);
app.component("CardComponent", CardComponent);
app.component("CarouselCompoent", CarouselCompoent);
app.component("CRUDComponent", CRUDComponent);
app.component("ContainerComponent", ContainerComponent);
app.component("InputFileComponent", InputFileComponent);
app.component('QuillEditor', QuillEditor)
app.component('SelectlistComponent', SelectlistComponent)
app.component("RoundedLinkComponent", RoundedLinkComponent)
app.component("DisplayRevestimentoComponent", DisplayRevestimentoComponent)

app.use(router);
app.use(VueAxios, axios);
app.use(Maska);
app.use(VueSweetalert2);
app.use(pinia);
app.mount('#app');
