import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`RoundedLink
                border-2
                border-yellow-400

                rounded-[30px]
                md:rounded-[40px]
                lg:rounded-[50px]
                overflow-hidden
                min-w-min

                ${_ctx.sizeStr}
                `),
    style: _normalizeStyle(`${_ctx.backgroundColor != '' ? 'background-color:'+_ctx.backgroundColor+';' : ''}`)
  }, [
    _renderSlot(_ctx.$slots, "default")
  ], 6))
}