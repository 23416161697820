
import { defineComponent } from 'vue'
import { mdiFacebook, mdiInstagram, mdiYoutube, mdiLinkedin } from '@mdi/js';
import SvgIcon from '@jamescoyle/vue-icon';

export default defineComponent({
    components: {
        SvgIcon
    },
    data() {
        return {
            mdiFacebook: mdiFacebook,
            mdiInstagram: mdiInstagram,
            mdiYoutube: mdiYoutube,
            mdiLinkedin: mdiLinkedin,
            isMobile: window.innerWidth <= 1350 as Boolean,
        }
    },
    methods: {
        handleResize() {
            this.isMobile = window.innerWidth <= 500
        },
    },
    mounted() {
        window.addEventListener('resize', this.handleResize);
    },
})
