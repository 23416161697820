
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    size: {
      type: String,
      default: ''
    },
    backgroundColor: {
        type: String,
        default: '' as String
    }
  },
  setup(props) {
    let sizeStr = '';
    
    switch (props.size) {
    case 'sm':
        sizeStr = ` w-[120px]
                    md:w-[170px]
                    lg:w-[220px]

                    h-[100px]
                    md:h-[150px]
                    lg:h-[180px] `;
        break;
    case 'md':
        sizeStr = ` w-[156px]
                    md:w-[221px]
                    lg:w-[286px]

                    h-[130px]
                    md:h-[195px]
                    lg:h-[234px] `;
        break;
    case 'lg':
        sizeStr = ` w-[192px]
                    md:w-[272px]
                    lg:w-[352px]

                    h-[160px]
                    md:h-[240px]
                    lg:h-[288px] `;
        break;
    }

    return {
      sizeStr
    };
  }
});
