
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        label: {
            type: String,
            required: false,
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            required: false,
            default: () =>{ return "all"}
        },
        error: {
            type: String,
            required: false
        },
        modelValue: {
            type: [String, Number],
            required: false as boolean,
        },
    },
    data() {
        return {
            fileName: "" as String,
            dataFile: null as any,
            typeInput: this.getTypeInput() as string,
        }
    },
    methods: {
        getTypeInput() :string {
                if (this.type == 'img') {
                    return 'image/*'
                } else if(this.type == 'doc') {
                    return '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document'
                }
                return '*'
        },
        // AjustaVModel(event: Event) {
        //     const target = event.target as HTMLInputElement;
        // },
        async onFileSelected(event: Event | any) {
            const file = event.target.files[0];
            if (file) {
                this.fileName = file?.name
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.dataFile = e.target?.result;
                };
                reader.readAsDataURL(file);
            } else {
                this.dataFile = null;
                this.fileName = "";
            }
        },
    },
    watch: {
        dataFile(currentValue: String) {
            this.$emit("update:modelValue", currentValue);
        }
    }
})
