
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        linha1: {
            type: String,
            required: false as boolean,
        },
        linha2: {
            type: [String, Number],
            required: false as boolean,
        },
        abaixo: {
            type: [String, Number],
            required: false as boolean,
        },
        imgSrc: {
            type: String,
            required: false as boolean,
        }
    }
});
