import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "App"
}
const _hoisted_2 = {
  id: "header",
  class: "max-h-24 h-24"
}
const _hoisted_3 = { id: "body" }
const _hoisted_4 = {
  id: "footer",
  class: "lg:max-h-[11rem] lg:h-[11rem] md:max-h-[11rem] md:h-[11rem]"
}
const _hoisted_5 = {
  key: 1,
  class: "App"
}
const _hoisted_6 = { id: "body" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NavBarComponent = _resolveComponent("NavBarComponent")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FooterComponent = _resolveComponent("FooterComponent")!
  const _component_ConstrucaoComponent = _resolveComponent("ConstrucaoComponent")!

  return (!_ctx.is404 && !_ctx.isConstruction)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("header", _hoisted_2, [
          _createVNode(_component_NavBarComponent)
        ]),
        _createElementVNode("main", _hoisted_3, [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                mode: "out-in",
                name: "scale"
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _createElementVNode("footer", _hoisted_4, [
          _createVNode(_component_FooterComponent)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_5, [
        _createElementVNode("main", _hoisted_6, [
          _createVNode(_component_ConstrucaoComponent)
        ])
      ]))
}