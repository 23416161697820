import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, mergeProps as _mergeProps, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["id", "type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", _mergeProps({
    id: _ctx.id,
    class: _ctx.classButton,
    type: _ctx.type
  }, _ctx.$attrs), [
    _createElementVNode("span", null, _toDisplayString(_ctx.label), 1)
  ], 16, _hoisted_1))
}