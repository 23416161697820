
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        label: {
            type: [String, Number],
            required: false as boolean,
        },
        options: {
            type: [Object],
            required: false as boolean,
        },
        name: {
            type: [String],
            required: true as boolean,
        },
        id: {
            type: [String],
            required: false as boolean,
        },
        placeholder: {
            type: [String],
            default: () => "Selecione:",
        },
        value: {
            type: [String],
            required: false as boolean,
        },
        pesquisa: {
            type: [Boolean],
            required: false as boolean,
        },
        modelValue: {
            type: [String, Number],
            required: false as boolean,
        },
        divClass: {
            type: [String],
            default: () => "border border-black bg-white my-2 px-4 py-2 w-max-full w-full relative flex items-center",
        },
        selectClass: {
            type: [String],
            default: () => "block w-full h-[36px] bg-white text-slate-700 box-border focus:outline-0",
        }
    },
    data() {
        return {
            data: [] as string[],
        };
    },
    methods: {
        AjustaVModel() {            
            const target = this.$el.querySelector('select') as HTMLInputElement;
            
            this.$emit("update:modelValue", target.value);
        },
    },
    mounted() {
        setTimeout(() => {
            this.AjustaVModel()
        }, 1000);
    },
    updated() {
        
    },
});
