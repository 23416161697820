
import { defineComponent } from 'vue';
import { GetDataRoute, GetDataRouteUpdate, Create, Update, Delete } from '../helpers/crud'
import { DataObject } from '../helpers/types'
import Swal from "sweetalert2";

export default defineComponent({
    props: {
        apiRoute: {
            type: String,
            required:true as boolean
        },
        title: {
            type: String,
            required:true as boolean
        },
        columns: {
            type: Object,
            required: false as boolean,
        },
    },
    data() {
        return {
            currentAction: "" as String,
            dataRoute: [] as DataObject[],
            dataRouteItem: {} as DataObject,
            Id: "" as String,
        }
    },
    methods: {
        Voltar() :void{
            location.reload();
        },
        async Submit() :Promise<void> {
            Swal.fire({
                title: '<h1 class="text-lg font-bold mb-2 lg:text-2xl md:text-2xl">Aguarde alguns instantes</h1>',
                html: '<span class="md:text-2xl lg:text-2xl text-justify text-lg mb-16">Estamso processando seu pedido...</span>',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });
            const fields = document.querySelectorAll("form")[0];

            const formData = new FormData();
            for (const key in fields.children) {
                if (fields.children[key].lastChild) {
                    const Element = fields.children[key];
                    if (!Element.classList.contains('ignore')) {
                        const Input = Element.querySelectorAll("input")[0] || Element.querySelectorAll("textarea")[0] || Element.querySelectorAll("select")[0];
                        
                        if (Input) {
                            const Value = Input.value;
                            const Name = Input.name;
                            
                            formData.append(Name, Value);
                        }
                    }
                }
            }
            
            if (this.currentAction == "C") {
                await Create(formData, this.apiRoute as string);
            } else {
                const url = `${this.apiRoute}/${this.Id}` as string;
                await Update(formData, url);
            }
        },
        async SetCurrentAction(acao: String) :Promise<String> {
            if (this.currentAction == "T" || this.currentAction == "") {
                this.currentAction = acao;
            } else {
                this.currentAction = "T";
            }
            return this.currentAction;
        },
        async SetDataRoute() :Promise<void> {
            this.dataRoute = (await GetDataRoute(this.apiRoute as string)) || [];
        },
        async SetDataRouteUpdate(id: String) :Promise<void> {
            this.dataRouteItem = (await GetDataRouteUpdate(this.apiRoute as string, id)) || [];
        },
        async Update(id: String) :Promise<void> {
            await this.SetCurrentAction("U");
            await this.SetDataRouteUpdate(id);
            this.Id = id;
            this.SetFieldsAction();
        },
        SetFieldsAction() :void {
            const fields = document.querySelectorAll("form")[0];
            
            for (const key in fields.children) {
                if (fields.children[key].lastChild) {
                    const Element = fields.children[key];
                    if (!Element.classList.contains('ignore')) {
                        const Input = Element.querySelectorAll("input")[0];
                        const Textarea = Element.querySelectorAll("textarea")[0];
                        const Select = Element.querySelectorAll("select")[0];
                        
                        if (Input) {
                            const Name = Input.name as string;
                            Input.value = this.dataRouteItem[Name]
                        }

                        if (Textarea) {
                            const Name = Textarea.name as string;
                            Textarea.value = this.dataRouteItem[Name]
                        }

                        if (Select) {
                            const Name = Select.name as string;
                            Select.value = this.dataRouteItem[Name]
                        }
                    }
                }
            }
        },
        Remove(id: string) :void {
            Swal.fire({
                title: "Tem certeza?",
                text: "Você não será capaz de reverter isso!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: "Sim, exclua!",
                cancelButtonText: "Cancelar",
            }).then(async (ButtonClick) => {
                if (ButtonClick.isConfirmed) {
                    const url = `${this.apiRoute}/${id}` as string;
                    await Delete(url);
                }
            });
        },
    },
    async mounted() {
        await this.SetCurrentAction("T");
        await this.SetDataRoute();
    },
    
});
