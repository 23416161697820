import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "left flex items-end flex-col grow lg:basis-[30%] md:basis-[30%] basis-[22%]" }
const _hoisted_2 = { class: "lg:w-40 md:w-28 w-20 px-2 pb-[0.15rem] border-b-2 border-l-2 rounded-bl-xl border-neutral-500 flex flex-col" }
const _hoisted_3 = { class: "lg:text-base md:text-base text-sm text-slate-600 md:mb-[-10px] lg:mb-[-10px] mb-[-5px]" }
const _hoisted_4 = { class: "lg:text-3xl md:text-xl font-bold text-slate-600" }
const _hoisted_5 = { class: "text-sm pl-4 text-slate-600" }
const _hoisted_6 = { class: "right grow lg:basis-2/3 md:basis-2/3 basis-[78%] pt-16" }
const _hoisted_7 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(`flex items-center gap-2 ${_ctx.linha2 == 'Concept' ? 'lg:mb-8 md:mb-4 mb-2 lg:mt-8 md:mt-4 mt-2' : ''}`)
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.linha1), 1),
        _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.linha2), 1)
      ]),
      _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.abaixo), 1)
    ]),
    _createElementVNode("div", _hoisted_6, [
      _createElementVNode("img", {
        class: "lg:w-[70%] md:w-[70%] w-full",
        src: _ctx.imgSrc,
        alt: `Neoline Linha ${_ctx.linha2}`
      }, null, 8, _hoisted_7)
    ])
  ], 2))
}