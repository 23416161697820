import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, mergeProps as _mergeProps, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-ad18cb7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "placeholder", "value", "id", "name", "disabled", "modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _directive_maska = _resolveDirective("maska")!

  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(`width: ${_ctx.width}%; border-color:${_ctx.borderColor}; border-radius:${_ctx.radius};`),
    class: "inputComponent"
  }, [
    (_ctx.prependIconPath)
      ? (_openBlock(), _createBlock(_component_svg_icon, {
          key: 0,
          path: _ctx.prependIconPath,
          type: "mdi",
          class: "mr-2"
        }, null, 8, ["path"]))
      : _createCommentVNode("", true),
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 1,
          class: "mr-8",
          for: _ctx.id || _ctx.name
        }, _toDisplayString(_ctx.label) + ": ", 9, _hoisted_1))
      : _createCommentVNode("", true),
    _withDirectives(_createElementVNode("input", _mergeProps({
      type: _ctx.type,
      placeholder: _ctx.placeholder
    }, _ctx.$attrs, {
      value: _ctx.modelValue || _ctx.value || _ctx.stateValue,
      id: _ctx.id || _ctx.name,
      name: _ctx.name,
      disabled: 'disabled' in _ctx.$attrs,
      modelValue: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.AjustaVModel($event))),
      onPaste: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('paste', $event))),
      onFocusin: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focusin', $event))),
      onFocusout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('focusout', $event))),
      onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('focus', $event))),
      onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('change', $event))),
      onKeydown: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('keydown', $event))),
      onKeyup: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('keyup', $event))),
      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('click', $event)))
    }), null, 16, _hoisted_2), [
      [_directive_maska, _ctx.formato]
    ])
  ], 4))
}