import axios, { AxiosResponse, AxiosError } from 'axios'
import Swal from "sweetalert2";

export { GetDataRoute, GetDataRouteUpdate, Create, Update, Delete };
    
const GetDataRoute = async function GetDataRoute(url: string) : Promise<any> {
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    };

    return await axios
        .get(url, config)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((errors: AxiosError) => {
            return errors.response;
        });
};

const GetDataRouteUpdate = async function GetDataRouteUpdate(url: string, id: String) : Promise<any> {
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    };

    const route = `${url}/${id}`

    return await axios
        .get(route, config)
        .then((response: AxiosResponse) => {
            return response.data;
        })
        .catch((errors: AxiosError) => {
            return errors.response;
        });
};

const Create = async function Create(formData: FormData, url: string) : Promise<void> {

    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    };

    await axios
        .post(url, formData, config)
        .then((response: AxiosResponse) => {
            Swal.close();
            // location.reload();
        })
        .catch((errors: AxiosError) => {
            Swal.fire({
                title: 'Erro!',
                text: 'Tivemos um erro inesperado, por favor contate o Administrador do sistema',
                icon: 'error',
                confirmButtonColor: '#d33',
                confirmButtonText: ' Ok '
            });
        });
};

const Update = async function Update(formData: FormData, url: string) : Promise<void> {

    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    };

    await axios
        .put(url, formData, config)
        .then((response: AxiosResponse) => {
            Swal.close();
            location.reload();
        })
        .catch((errors: AxiosError) => {
            Swal.fire({
                title: 'Erro!',
                text: 'Tivemos um erro inesperado, por favor contate o Administrador do sistema',
                icon: 'error',
                confirmButtonColor: '#d33',
                confirmButtonText: ' Ok '
            });
        });
};

const Delete = async function Delete(url: string) : Promise<void> {
    const config = {
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
        },
    };

    await axios
        .delete(url, config)
        .then((response: AxiosResponse) => {
            Swal.close();
            location.reload();
        })
        .catch((errors: AxiosError) => {
            console.log(errors);
            
            Swal.fire({
                title: 'Erro!',
                text: 'Tivemos um erro inesperado, por favor contate o Administrador do sistema',
                icon: 'error',
                confirmButtonColor: '#d33',
                confirmButtonText: ' Ok '
            });
        });
};
