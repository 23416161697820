import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = ["type", "placeholder", "value", "id", "name", "disabled", "modelValue"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    style: _normalizeStyle(`width: ${_ctx.width}%; border-color:${_ctx.borderColor}; border-radius:${_ctx.radius};`),
    class: "flex relative max-w-full border px-4 py-2 my-2"
  }, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "mr-8",
          for: _ctx.id || _ctx.name
        }, _toDisplayString(_ctx.label) + ": ", 9, _hoisted_1))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", _mergeProps({
      class: "w-full resize-none focus:outline-none text-slate-500",
      type: _ctx.type,
      placeholder: _ctx.placeholder
    }, _ctx.$attrs, {
      value: _ctx.modelValue || _ctx.value,
      id: _ctx.id || _ctx.name,
      name: _ctx.name,
      disabled: 'disabled' in _ctx.$attrs,
      modelValue: _ctx.modelValue,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.AjustaVModel($event))),
      onPaste: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('paste', $event))),
      onFocusin: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('focusin', $event))),
      onFocusout: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('focusout', $event))),
      onFocus: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('focus', $event))),
      onChange: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('change', $event))),
      onKeydown: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('keydown', $event))),
      onKeyup: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$emit('keyup', $event))),
      onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.$emit('click', $event))),
      cols: "30",
      rows: "10"
    }), null, 16, _hoisted_2)
  ], 4))
}