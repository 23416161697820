import { defineStore } from 'pinia'
import Swal from "sweetalert2";
import axios, { AxiosResponse, AxiosError, isAxiosError } from 'axios'
import { InfoUser } from '../helpers/types'

export const GetDataUser = defineStore('user', {
    state: () => ({
        /**
         * Informações do usuário
         * @type InfoUser
         */
        info: {} as InfoUser,
    }),
    actions: {
        async Login(email: String, password: String): Promise<void> {
            const formData = {
                email: email,
                password: password
            }

            Swal.fire({
                title: '<h1 class="text-lg font-bold mb-2 lg:text-2xl md:text-2xl">Aguarde alguns instantes</h1>',
                html: '<span class="md:text-2xl lg:text-2xl text-justify text-lg mb-16">Estamso processando seu pedido...</span>',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading()
                },
            });
            await axios.post("login", formData)
            .then((response: AxiosResponse) => {
                if (!isAxiosError(response)) {

                    this.info = response.data;
                    localStorage.setItem("token", response.data.token);
                    axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
                    Swal.close();
                } else {
                    const HttpErrorResponse = response as AxiosError
                    const titulo = HttpErrorResponse.response?.status == 401 ? "Usuário não encontrado" : "Erro!" 
                    const message = HttpErrorResponse.response?.status == 401 ? "Verifique seu Email ou senha" : "Tivemos um erro inesperado, por favor contate o Administrador do sistema";
                    Swal.close();
                    Swal.fire({
                        title: titulo,
                        text: message,
                        icon: 'error',
                        confirmButtonColor: '#d33',
                        confirmButtonText: ' Ok '
                    });
                    
                }
            })
            // .catch((error: AxiosError) => {
                    

            // });
        },
        async VerifyUser(): Promise<void> {
            if (localStorage.getItem("token")) {
                axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
            }
            
            await axios.get("/user")
            .then((response: AxiosResponse) => {
                this.info = response.data;
                axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
            })
            .catch((error: AxiosError) => {
                if (error.response?.status != 401) {
                    Swal.fire({
                        title: 'Erro!',
                        text: 'Tivemos um erro inesperado, por favor contate o Administrador do sistema',
                        icon: 'error',
                        confirmButtonColor: '#d33',
                        confirmButtonText: ' Ok '
                    });
                }    
            });
        },
        async Logout(): Promise<void> {
            if (this.info.token) {
                axios.post("/logout")
                .then(() => {
                    this.info = {} as InfoUser;
                });
            }
            localStorage.removeItem("token");
        }
    }

})
